import "./querybutton.css";

const QueryButton = ({ btntext, btnclick, customstyle, type }) => {
  return (
    <>
      <button
        className="querybutton"
        disabled={false}
        type={type ?? ""}
        onClick={btnclick}
        style={customstyle}
      >
        {btntext}
      </button>
    </>
  );
};

export default QueryButton;
