import React, { useState, useEffect } from "react";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import loginimg from "../../../assest/image/loginbkg.png";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loginbutton from "../../../components/common/loginButton/loginbutton";
import { handleLoginAPi } from "../../../redux/action/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../redux/slices/authSlice";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eyeDefault, setEyeDefault] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const selector = useSelector(authSelector);
  const [savedUsername] = useState("");
  const [savedPassword] = useState("");

  useEffect(() => {
    if (localStorage.getItem("rememberMe") === "true") {
      formik.setFieldValue("username", localStorage.getItem("username"));
      formik.setFieldValue("password", localStorage.getItem("password"));
      setRememberMe(true);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      username: savedUsername || "",
      password: savedPassword || "",
    },

    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
    }),

    onSubmit: (values) => {
      dispatch(handleLoginAPi(navigate, values, rememberMe));
      if (rememberMe) {
        localStorage.setItem("rememberMe", "true");
        localStorage.setItem("username", values.username);
        localStorage.setItem("password", values.password);
      } else {
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("username");
        localStorage.removeItem("password");
      }
    },
  });

  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="login-box">
          <h1 className="login-title">Login</h1>
          <div className="underline"></div>
          <form onSubmit={formik.handleSubmit}>
            {formik.errors.username && formik.touched.username ? (
              <p className="error-txt">Username is required</p>
            ) : (
              ""
            )}
            <div
              className={
                formik.errors.username && formik.touched.username
                  ? "input-group-red"
                  : "input-group"
              }
            >
              <span className="icon">
                <PersonIcon />
              </span>{" "}
              <input
                type="name"
                placeholder="Enter username"
                name="username"
                id="username"
                onChange={formik.handleChange}
                value={formik.values.username}
                className="input-field"
              />
            </div>

            {formik.errors.password && formik.touched.password ? (
              <p className="error-txt">Password is required</p>
            ) : (
              ""
            )}
            <div
              className={
                formik.errors.password && formik.touched.password
                  ? "input-group-red"
                  : "input-group"
              }
            >
              <span className="icon">
                <LockIcon />
              </span>{" "}
              <input
                type={eyeDefault ? "text" : "password"}
                placeholder="Enter password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                className="input-field"
              />
              <span
                className="icon-eye"
                onClick={() => setEyeDefault(!eyeDefault)}
              >
                {eyeDefault ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="remember-me">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <label>Remember Me</label>
              </div>

              <div className="forgot-password">
                <a
                  href="#"
                  className="fr-btn"
                  onClick={() => navigate("/forgotPassword")}
                >
                  Forgot Password?
                </a>
              </div>
            </div>

            {Loginbutton({
              text: "Login",
              type: "submit",
              loader: selector.singInLoading,
            })}
          </form>
        </div>
      </div>

      <div className="login-image-container">
        <img className="robot-image" src={loginimg} alt="img" />
      </div>
    </div>
  );
};

export default LoginPage;
