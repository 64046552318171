import Grid from "@mui/material/Grid2";
import SuggestionDropdown from "../common/suggestionDropdown";
import QueryButton from "../common/querybutton";
import "./suggestion.css";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FailureToast } from "../../utils/commonunctions";
import { Button } from "@mui/material";
import AddSuggestionModal from "./addSuggestionModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CommentIcon from "@mui/icons-material/Comment";
import {
  createClassesApi,
  editClassesApi,
  getChoiceApi,
  getClassdataApi,
} from "../../redux/action/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/slices/userSlice";
import Loader from "../loader";
import { iconfun } from "../../utils/helperdata";
import AlertDialogSlide from "../sidebar/deleteModal";

const SuggestionContent = ({
  onGenerateQuery,
  setSuggestionValue,
  ismodify,
  resdata,
  generateSentence,
  oldOptions,
  isModifyQuery,
  setOptions,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allClassData, classDataLoading, modalClassesLoading, getChoices } =
    useSelector(userSelector);

  const [shouldFetch, setShouldFetch] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleOpen = (id) => {
    setOpen(true);
    setDeleteId(id);
  };

  const initialForm = {
    name: "",
    icon: "",
    is_deleted: true,
    is_option: false,
  };
  const [formData, setFormData] = useState(initialForm);
  const handleAddClick = (action, formData) => {
    setIsModalOpen(true);
    setIsEditModel(false);
    if (action === "edit") {
      setIsEditModel(true);
      setFormData(formData);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialForm);
  };

  const defaultClassNames = allClassData?.map((item) => item.name);
  const handleSaveData = async (newData) => {
    if (defaultClassNames.includes(newData.name.toLowerCase())) {
      return FailureToast("Classes already exist");
    } else {
      dispatch(
        createClassesApi(
          newData,
          setShouldFetch,
          setIsModalOpen,
          setFormData,
          initialForm
        )
      );
    }
  };

  const handleEditClass = async (newData) => {
    const body = {
      is_option: newData.is_option,
      name: newData.name,
    };
    if (defaultClassNames.includes(newData.name.toLowerCase())) {
      return FailureToast("Classes already exist");
    } else {
      dispatch(
        editClassesApi(
          setShouldFetch,
          setIsModalOpen,
          setFormData,
          initialForm,
          newData,
          body
        )
      );
    }
  };

  const handleEdit = (id) => {
    const findoptionLabel = () => {
      return getChoices?.reduce((acc, item) => {
        if (item?.choice_type.toLowerCase() === id?.toLowerCase()) {
          return [
            ...acc,
            { label: item.value, value: item.value, id: item.id },
          ];
        }
        return acc;
      }, []);
    };

    const finaldata = findoptionLabel();

    navigate("/edit", {
      state: { finaldata, id, isEdit: true },
    });
  };

  const optionData = resdata?.data?.queries?.[0]?.options;
  const formik = useFormik({
    initialValues: {
      subject: "",
    },
    validateOnChange: true,

    onSubmit: (values) => {
      // const filteredData = Object.keys(values).reduce((acc, key) => {
      //   if (values[key] && values[key].trim() !== "") {
      //     acc[key] = values[key];
      //   }
      //   return acc;
      // }, {});

      const suggestionvalue = Object.keys(values).reduce((acc, key) => {
        if (values[key] && values[key].trim() !== "") {
          const modifiedKey = key === "output" ? "Output" : key;
          acc[modifiedKey] = values[key];
        }
        return acc;
      }, {});

      setSuggestionValue(suggestionvalue);

      onGenerateQuery(values);
    },
  });

  useEffect(() => {
    dispatch(getChoiceApi());
  }, []);

  useEffect(() => {
    setOptions(formik.values);
  }, [formik.values]);

  // useEffect(() => {
  //   if (optionData && typeof optionData === "object") {
  //     Object.keys(optionData).forEach((key) => {
  //       let updatedKey = key;
  //       if (key === "Output") {
  //         updatedKey = "output";
  //       }
  //       formik.setFieldValue(updatedKey, optionData[key]);
  //     });
  //   }
  // }, [resdata]);

  const handleValidation = (e) => {
    if (!formik?.values?.subject?.trim()) {
      FailureToast("Subject is a required field");
    }

    if (formik?.values?.subject) {
      generateSentence(formik?.values);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (shouldFetch) {
        dispatch(getClassdataApi());
        setShouldFetch(false);
      }
    };
    fetchData();
  }, [shouldFetch]);

  const choiceOption = (e) => {
    const selectedValue = e?.target?.value;
    const selectName = e?.target?.name;
    formik.setFieldValue(selectName, selectedValue);
  };

  useEffect(() => {
    if (oldOptions) {
      const formattedOptions = Object.keys(oldOptions).reduce((acc, key) => {
        acc[key.toLowerCase()] = oldOptions[key];
        return acc;
      }, {});

      formik.resetForm({ values: formattedOptions });
    }
  }, [oldOptions, isModifyQuery]);

  return (
    <>
      {open && (
        <AlertDialogSlide
          open={open}
          setOpen={setOpen}
          setShouldFetch={setShouldFetch}
          deleteId={deleteId}
        />
      )}

      {modalClassesLoading ? (
        <Loader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Button
            className="add-btn"
            variant="contained"
            onClick={() => handleAddClick("create", null)}
          >
            + Add Class
          </Button>
          <Grid
            className="container"
            container
            alignItems="stretch"
            spacing={2}
          >
            {classDataLoading ? (
              <Loader />
            ) : (
              allClassData?.map((data) => {
                return (
                  <>
                    <Grid
                      size={{ lg: 2, md: 4, xs: 4 }}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "80%" }}>
                        <div className="suggestionContainer">
                          <p
                            key={data?.name}
                            style={{ margin: 0, width: "50%" }}
                          >
                            {data?.name}
                          </p>
                          {data?.icon ? (
                            <img
                              className="img-icon"
                              src={iconfun(data?.icon)}
                              alt="img"
                            />
                          ) : (
                            <CommentIcon
                              sx={{
                                fontSize: "16px",
                                width: "40%",
                                color: "green",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="add-subject">
                        <div>
                          {data?.is_deleted === true ? (
                            <ModeEditOutlineIcon
                              sx={{
                                fontSize: "18px",
                                color: "#4b5669",
                                cursor: "pointer",
                              }}
                              onClick={() => handleAddClick("edit", data)}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          {data?.is_deleted === true ? (
                            <DeleteOutlineIcon
                              sx={{
                                fontSize: "18px",
                                color: "#4b5669",
                                cursor: "pointer",
                              }}
                              onClick={() => handleOpen(data?.id)}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Grid>

                    <Grid size={{ lg: 2, md: 3, xs: 4 }}>
                      {data?.is_option && (
                        <div
                          key={data?.is_option}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <SuggestionDropdown
                            id={data?.name}
                            formikvalues={formik.values}
                            options={getChoices}
                            onChange={(e) => choiceOption(e)}
                            ismodify={ismodify}
                          />
                          <EditNoteIcon
                            onClick={() => handleEdit(data?.name)}
                            style={{ color: "#F29D38", cursor: "pointer" }}
                          />
                        </div>
                      )}
                    </Grid>

                    <Grid size={{ lg: 8, md: 3, xs: 4 }}>
                      <input
                        value={formik.values[data?.name]}
                        onChange={formik.handleChange}
                        name={data?.name}
                        disabled={data?.is_option ? true : false}
                        placeholder={
                          data?.name === "output" ||
                          data?.name === "language" ||
                          data?.name === "length"
                            ? "choose"
                            : "Fill in"
                        }
                        type="text"
                        className="suggestioninput"
                      />
                    </Grid>
                  </>
                );
              })
            )}

            <div className="suggestioncontentbtn">
              <QueryButton
                type={"submit"}
                btntext={"Generate Query"}
                btnclick={(e) => handleValidation(e)}
              />
              <QueryButton
                type={"button"}
                btntext={"Save Query"}
                btnclick={handleValidation}
              />
            </div>
          </Grid>
        </form>
      )}

      <AddSuggestionModal
        formData={formData}
        setFormData={setFormData}
        open={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveData}
        onEdit={handleEditClass}
        isEditModel={isEditModal}
      />
    </>
  );
};

export default SuggestionContent;
