import React from "react";
import CommonForm from "../../../components/common/LoginForm/CommonForm";
import LockIcon from "@mui/icons-material/Lock";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChangePasswordApi } from "../../../redux/action/UserAction";

export default function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikchgpass = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("password is required"),
      confirmPassword: Yup.string().required("password is required"),
    }),

    onSubmit: (values) => {
      const body = {
        old_password: values?.password,
        new_password: values?.confirmPassword,
      };
      dispatch(ChangePasswordApi(body, navigate));
    },
  });
  const Resetform = {
    title: "Change Password",
    onsubmit: formikchgpass.handleSubmit,
    btnText: "Submit",
    onClick: formikchgpass.handleSubmit,
    backpath: -1,
    inputGroups: [
      {
        type: "password",
        placeholder: "Enter old password",
        name: "password",
        onChange: formikchgpass?.handleChange,
        icon: <LockIcon />,
        value: formikchgpass.values.password,
        error: formikchgpass.errors.password,
        touched: formikchgpass.touched.password,
      },
      {
        type: "password",
        placeholder: "Enter new password",
        name: "confirmPassword",
        onChange: formikchgpass?.handleChange,
        icon: <LockIcon />,
        value: formikchgpass.values.confirmPassword,
        error: formikchgpass.errors.confirmPassword,
        touched: formikchgpass.touched.confirmPassword,
        eyeicon: true,
      },
    ],
  };
  return (
    <div className="login-container">
      <div className="login-form-container">{CommonForm(Resetform)}</div>
    </div>
  );
}
