import { useRoutes } from "react-router-dom";
import "./App.css";
import { getRoutes } from "./layout/routes";
function App() {
  let routeType = sessionStorage.getItem("UR") || 0;
  const router = useRoutes(getRoutes(routeType));

  return (
    <div>
      {router}
    </div>
  );
}

export default App;
