import axios from "axios";

const getToken = () => sessionStorage.getItem("accessToken");
const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => response, 
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem("accessToken"); 
      window.location.href = "/"; 
    }
    return Promise.reject(error); 
  }
);

export const api = {
  post: (url, data) => {
    const token = getToken();
    return axios.post(url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  delete: (url, data) => {
    const token = getToken();
    return axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
      data: data ? data : {},
    });
  },
  get: (url, params) => {
    const token = getToken();
    return axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
  },
  put: (url, data) => {
    const token = getToken();
    return axios.put(url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  patch: (url, data) => {
    const token = getToken();
    return axios.patch(url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

export const authApi = {
  post: (url, data) => axios.post(url, data),
};
