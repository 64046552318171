import { loginUserReducer } from "../slices/authSlice";
import { authApi } from "../../utils/api";
import { BASE_URL } from "../../constants";
import { FailureToast, SuccessToast } from "../../utils/commonunctions";

export const handleLoginAPi = (navigate, data) => {
  return async (dispatch) => {
    await dispatch(loginUserReducer({ singInLoading: true }));
    await authApi
      .post(`${BASE_URL}/api/token/`, data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loginUserReducer({ singInLoading: false }));
          sessionStorage.setItem("UR", 1);
          sessionStorage.setItem("accessToken", res?.data?.access);
          sessionStorage.setItem("refreshToken", res?.data?.refresh);
          SuccessToast("Logged in Successfully");
          setTimeout(() => navigate("/"), 1500);
        } else {
          FailureToast("Unexpected Response From Server.");
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          FailureToast(error.response?.data?.detail);
          dispatch(loginUserReducer({ singInLoading: false }));
        } else {
          const errorMessage =
            error.response?.data?.message ||
            error.message ||
            "An unknown error occurred";
          FailureToast(errorMessage);
          dispatch(loginUserReducer({ singInLoading: false }));
        }
      });
  };
};

export const handleForgotApi = (setIsLoading, values, navigate) => {
  setIsLoading(true);
  return async (dispatch) => {
    await authApi
      .post(`${BASE_URL}/forgot_password/`, values)
      .then((res) => {
        if (res?.status === 200) {
          setIsLoading(false);
          SuccessToast(res?.data?.message);

          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        FailureToast(err?.response?.data?.error);
      });
  };
};
