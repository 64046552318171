import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";
import {
  clearAllConversationApi,
  deleteClassesApi,
} from "../../redux/action/UserAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  setOpen,
  setShouldFetch,
  deleteId,
}) {
  const dispatch = useDispatch();
  const handleDelete = async () => {
    dispatch(clearAllConversationApi(setOpen));
  };

  const deleteOnsubmit = async () => {
    dispatch(deleteClassesApi(setOpen, setShouldFetch, deleteId));
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpen}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontFamily: "lufga" }}>
          {"Are you sure you want to delete?"}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              fontFamily: "lufga",
              color: "black",
              border: "1px solid black",
            }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ fontFamily: "lufga", backgroundColor: "black" }}
            onClick={setShouldFetch ? deleteOnsubmit : handleDelete}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
