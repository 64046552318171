import React, { useState } from "react";
import Loginbutton from "../loginButton/loginbutton";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

function CommonForm(formDatas) {
  const [eyeDefault, setEyeDefault] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <form onSubmit={formDatas.onsubmit}>
        <h1 className="login-title">{formDatas?.title}</h1>
        <div className="underline"></div>
        {formDatas?.inputGroups?.map((item) => (
          <>
            {item?.error && item?.touched ? (
              <p className="password-alert">
                {item?.error === "Strong password required."
                  ? item?.error
                  : "This field is required"}
              </p>
            ) : (
              ""
            )}
            <div
              className={
                item.error && item.touched ? "input-group-red" : "input-group"
              }
            >
              <span className="icon">{item.icon}</span>{" "}
              <input
                type={eyeDefault ? "password" : "text"}
                placeholder={item.placeholder}
                name={item.name}
                className="input-field"
                onChange={item.onChange}
                value={item.value}
              />
              {item?.eyeicon === true ? (
                <span
                  className="icon-eye"
                  onClick={() => setEyeDefault(!eyeDefault)}
                >
                  {eyeDefault ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        ))}

        {Loginbutton({
          text: formDatas.btnText,
          onClick: formDatas.onClick,
          loader: formDatas?.loader,
        })}
      </form>
      <div className="back-icon"
        onClick={(e) => {
          navigate(formDatas?.backpath); 
        }}
      >
        <ArrowBackIosOutlinedIcon />
        <p>Back</p>
      </div>
    </div>
  );
}

export default CommonForm;
