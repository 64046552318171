import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    singInLoading: false,
    forgetLoading: false,
  },
  reducers: {
    loginUserReducer: (state, { payload }) => {
        state.singInLoading = payload.singInLoading;
      },
    forgetUserReducer: (state, {payload}) => {
        state.forgetLoading = payload.forgetLoading;
    }
  },
});

export const { loginUserReducer, forgetUserReducer } = authSlice.actions;
export const authSelector = (state) => state.auth;
const authReducer = authSlice.reducer;
export default authReducer;