import "./sidebar.css";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MessageIcon from "@mui/icons-material/Message";
import { useCallback, useEffect, useState } from "react";
import { formatDateTime, getFormattedDate } from "../../utils/helperdata";
import AlertDialogSlide from "./deleteModal";
import {
  deleteConversationReduc,
  fetchAllConversationAPI,
  fetchAllDataAPI,
} from "../../redux/action/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { generateQueryReduc, userSelector } from "../../redux/slices/userSlice";
import Loader from "../loader";
import LogoutWrapper from "./logoutWrapper";

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const activedata = location?.state?.id;
  const {
    fetchConversation,
    allConversationData,
    recentLoading,
    convoLoading,
  } = useSelector(userSelector);
  const [open, setopen] = useState(false);

  useEffect(() => {
    dispatch(fetchAllDataAPI());
    dispatch(fetchAllConversationAPI());
  }, [dispatch]); 

  const handleNewChat = () => {
    navigate("/");
    window.location.reload();
    dispatch(
      generateQueryReduc({
        getGenerateQueryMessage: "",
        formikValues: "",
      })
    );
  };

  const handleLogout = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.setItem("UR", 0);
    navigate("/");
  };

  const handlepwdchange = () => {
    navigate("/changePassword");
  };

  const handleNavigation = useCallback(
    (i) => {
      navigate(`/response`, { state: { isHistory: true, id: i } });
    },
    [navigate]
  );
  const handleDeleteConversation = (id) => {
    dispatch(deleteConversationReduc(id, navigate));
  };

  return (
    <>
      <div className="container">
        <div>
          <div className="mainlogo">qtools.ai</div>
          <div className="hr"></div>
          <div className="maintitle">
            <AutoAwesomeIcon style={{ color: "#718096" }} />
            <span className="maincontentdate">{getFormattedDate()}</span>
          </div>
          <button className="chatbtn" onClick={handleNewChat}>
            + New Query
          </button>
          <div className="hr"></div>
          <div className="conversationarea">
            <p>Queries</p>
            <button onClick={() => setopen(true)}>Clear all</button>
          </div>
          <div className="hr"></div>
        </div>
        <div className="sidebar-scroll">
          {convoLoading ? (
            <Loader />
          ) : (
            allConversationData?.map((data, index) => {
              return (
                <div
                  key={data.conversation_id}
                  className={
                    activedata === data?.conversation_id
                      ? `chatflex-selected`
                      : `chatflex`
                  }
                  onClick={() => handleNavigation(data?.conversation_id)}
                >
                  <div className="icon-wrapper">
                    <MessageIcon
                      className={`${
                        activedata === data?.conversation_id
                          ? "msg-icn-selected"
                          : "msg-icn-unselected"
                      }`}
                    />
                  </div>
                  <div
                    className="historytitlesection"
                    style={{ fontWeight : activedata === data?.conversation_id ? "bolder" : "", }}>
                    <span className="sidebar-title" >
                      {data?.queries[0]?.options?.subject
                        ? data.queries[0].options.subject
                        : data?.title}
                    </span>
                    <span className="sidebar-date">
                      {formatDateTime(data?.queries[0]?.created_at)}
                    </span>
                  </div>
                  {activedata === data?.conversation_id && (
                    <span
                      className="actionbtn"
                      onClick={() =>
                        handleDeleteConversation(data.conversation_id)
                      }
                    >
                      <DeleteForeverIcon fontSize="18px" />
                    </span>
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>

      <div className="hr"></div>
      <p className="lastcontent">Last 7 Days</p>
      <div className="hr"></div>
      <div className="sidebar-scroll">
        {recentLoading ? (
          <Loader />
        ) : (
          fetchConversation?.map((data, index) => {
            return (
              <div
                key={data.conversation_id}
                className={
                  activedata === data?.conversation_id
                    ? `chatflex-selected`
                    : `chatflex`
                }
                onClick={() => handleNavigation(data?.conversation_id)}
              >
                <div className="icon-wrapper">
                  <MessageIcon
                    className={`${
                      activedata === data?.conversation_id
                        ? "msg-icn-selected"
                        : "msg-icn-unselected"
                    }`}
                  />
                </div>
                <div
                  className="historytitlesection" 
                  style={{fontWeight : activedata === data?.conversation_id ? "bolder" : "",}} >
                  <span className="sidebar-title">
                    {data?.queries[0]?.options?.subject
                      ? data.queries[0].options.subject
                      : data?.title}
                  </span>
                  <span className="sidebar-date">
                  {formatDateTime(data?.queries[0]?.created_at)}
                  </span>
                </div>
                {activedata === data?.conversation_id && (
                  <span
                    className="actionbtn"
                    onClick={() =>
                      handleDeleteConversation(data.conversation_id)
                    }
                  >
                    <DeleteForeverIcon fontSize="18px" />
                  </span>
                )}
              </div>
            );
          })
        )}
      </div>
          <div>
            <LogoutWrapper 
            handlepwdchange = {handlepwdchange}
            handleLogout = {handleLogout}
            />
          </div>

      <AlertDialogSlide
        open={open}
        setOpen={setopen}
      />
    </>
  );
};
export default SideBar;
