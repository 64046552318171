import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/slices/authSlice";
import userReducer from "../redux/slices/userSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
});

const store = configureStore({ reducer: rootReducer });

export default store;
