import React from "react";
import "./logoutWrapper.css";

const LogoutWrapper = ({ handlepwdchange, handleLogout }) => {
  return (
    <div className="logout-wrapper">
      <button className="chatbtn" onClick={handlepwdchange}>
        Change Password
      </button>
      <button className="chatbtn" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default LogoutWrapper;
