import MainContent from "../../components/maincontent";
import ChangePassword from "../../pages/auth/login/changePassword";
import EditSuggestion from "../../pages/user/editSuggestion";
import ResponseUi from "../../pages/user/responseui";
import Layout from "../navLayout";

export const publicRoutes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <MainContent />,
      },
      {
        path: "/response",
        element: <ResponseUi />,
      },
      {
        path: "/edit",
        element: <EditSuggestion />,
      },
      {
        path: "*",
        element: <ResponseUi />,
      },
    ],
  },
  {
    path: `/changePassword`,
    element: <ChangePassword />,
  },
];
