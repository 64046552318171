import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    recentLoading: false,
    fetchConversation: [],
    convoLoading: false,
    allConversationData: [],
    classDataLoading: false,
    allClassData: [],
    modalClassesLoading: false,
    conversationById: [],
    conversationLoading: false,
    modifyLoading: false,
    getChoices: [],
    getGenerateQueryMessage: [],
    formikValues: [],
    queryResponse: [],
    modifyClassData: [],
    optionsData: {},
  },

  reducers: {
    fetchAllData: (state, action) => {
      state.recentLoading = action.payload.recentLoading;
      state.fetchConversation = action.payload.fetchConversation;
    },
    allConversationReduc: (state, action) => {
      state.convoLoading = action.payload.convoLoading;
      state.allConversationData = action.payload.data;
    },
    classDataReduc: (state, action) => {
      state.classDataLoading = action.payload.classDataLoading;
      state.allClassData = action.payload.data;
      state.modifyClassData = action.payload.modifyClassData;
    },
    addClassesReduc: (state, action) => {
      state.modalClassesLoading = action.payload.modalClassesLoading;
    },
    editClassesReduc: (state, action) => {
      state.modalClassesLoading = action.payload.modalClassesLoading;
    },
    deleteClassesReduc: (state, action) => {
      state.modalClassesLoading = action.payload.modalClassesLoading;
    },
    conversationByIdReduc: (state, action) => {
      state.optionsData = action.payload.optionsData;
      state.conversationById = action.payload.data;
      state.conversationLoading = action.payload.conversationLoading;
    },
    modifyResponseReduc: (state, action) => {
      state.modifyLoading = action.payload.modifyLoading;
    },
    getChoiceReduc: (state, action) => {
      state.getChoices = action.payload.getChoices;
    },
    generateQueryReduc: (state, action) => {
      state.getGenerateQueryMessage = action.payload.getGenerateQueryMessage;
      state.formikValues = action.payload.formikValues;
    },
    queryResponseReduc: (state, action) => {
      state.queryResponse = action.payload.queryResponse;
    },
  },
});

export const {
  fetchAllData,
  allConversationReduc,
  classDataReduc,
  addClassesReduc,
  editClassesReduc,
  deleteClassesReduc,
  conversationByIdReduc,
  modifyResponseReduc,
  getChoiceReduc,
  generateQueryReduc,
  queryResponseReduc,
} = userSlice.actions;
export const userSelector = (state) => state.user;
const userReducer = userSlice.reducer;
export default userReducer;
