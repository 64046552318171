import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QueryButton from "../common/querybutton";
import SuggestionContent from "../suggestion";
import "./maincontent.css";
import { FailureToast, SuccessToast } from "../../utils/commonunctions";
import { useDispatch, useSelector } from "react-redux";
import { submitQueryApi } from "../../redux/action/UserAction";
import { userSelector } from "../../redux/slices/userSlice";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CircularIndeterminate } from "../../utils/helperdata";
const MainContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const oldquery = location?.state?.oldq;
  const isModifyQuery = location?.state?.isModifyQuery;
  const oldOptions = location?.state?.options;
  const { getGenerateQueryMessage } = useSelector(userSelector);
  const [isGenerating, setIsGenerating] = useState(false);
  const [editGenerateQuery, seteditGenerateQuery] = useState("");
  const [isOption, setOptions] = useState("");

  const generateSentence = (values) => {
    const { subject, output, language, length, audience, ...otherFields } =
      values;
    setOptions(values);
    let sentence = `${output || ""} ${subject || ""}`.trim();

    if (language) sentence += ` in ${language}`;
    if (length) sentence += ` within ${length}`;
    if (audience) sentence += ` for ${audience}`;

    const otherDetails = Object.entries(otherFields)
      .map(([key, value]) => value)
      .join(" ");
    if (otherDetails) sentence += " " + otherDetails;

    return seteditGenerateQuery(sentence.trim() + ".");
  };
  console.log(editGenerateQuery, oldquery, "hh");
  useEffect(() => {
    seteditGenerateQuery(oldquery);
  }, []);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(editGenerateQuery)
      .then(() => {
        SuccessToast(" Query Copied to clipboard!");
      })
      .catch((err) => {});
  };

  const submitQuery = async () => {
    if (editGenerateQuery) {
      dispatch(
        submitQueryApi(
          {
            query: editGenerateQuery,
            options: isOption,
            new_conversation: true,
          },
          setIsGenerating,
          navigate
        )
      );
    } else {
      setIsGenerating(false);
      FailureToast("Please generate the query");
    }
  };

  return (
    <div className="maincontentcontainer">
      <div className="maincontenthead">Query Construction</div>
      <SuggestionContent
        generateSentence={generateSentence}
        seteditGenerateQuery={seteditGenerateQuery}
        oldOptions={oldOptions}
        isModifyQuery={isModifyQuery}
        setOptions={setOptions}
      />
      <Grid container style={{ width: "100%" }} spacing={2}>
        <Grid size={{ xs: 2, md: 8, lg: 9 }}>
          {!editGenerateQuery ? (
            <div className="maincontentinput" readOnly>
              <p>Generated query</p>
            </div>
          ) : (
            <div className="textareacontainer">
              <textarea
                className="maincontentinput"
                value={editGenerateQuery}
                readOnly={!editGenerateQuery}
                onChange={(e) => seteditGenerateQuery(e.target.value)}
              />
              <div className="copyicon" onClick={handleCopy}>
                <ContentCopyOutlinedIcon />
                <span>Copy</span>
              </div>
            </div>
          )}
        </Grid>
        <Grid size={{ xs: 2, md: 8, lg: 3 }}>
          <QueryButton
            btntext={isGenerating ? CircularIndeterminate() : "Submit Query"}
            isDisabled={!getGenerateQueryMessage.query ? true : false}
            btnclick={() => submitQuery()}
            customstyle={{
              width: " 100%",
              height: "55px",
              fontSize: " 17px",
              borderRadius: "35px",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MainContent;
