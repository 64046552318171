import LoginPage from "../../pages/auth/login";
import ForgotPage from "../../pages/auth/login/forgotPage";
import ChangePassword from "../../pages/auth/login/changePassword";

export const authRoutes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPage />,
  },
  {
    path: `/changePassword/:uidb64?/:token?`,
    element: <ChangePassword />,
  },
];
