import "./responseui.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SuccessToast } from "../../../utils/commonunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  conversationByIdAPI,
  modifyResponseApi,
} from "../../../redux/action/UserAction";
import { userSelector } from "../../../redux/slices/userSlice";
import Loader from "../../../components/loader";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ModifySuggestion from "../../../components/suggestion/modifySuggestion";
import { formatResponseText } from "../../../utils/helperdata";

const ResponseUi = () => {
  const location = useLocation();
  let isHistory = location?.state?.isHistory ?? false;
  let id = location?.state?.id;
  let showres = location?.state?.showres;
  let newQuery = location?.state?.newQuery ?? false;
  const [ismodify, setIsModify] = useState(newQuery);
  const [response, setResponse] = useState("");
  const [query, setQuery] = useState();
  
  const [data] = useState();
  const [isLoading, setIsLoading] = useState(false);
 

  const { conversationById, modifyLoading } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [convoId, setConvoId] = useState("");
  let newId = convoId?.length<=0 ? id : convoId ;

  useEffect(() => {
    dispatch(conversationByIdAPI(id, isHistory, setIsLoading));
  }, [id, isHistory]);

  const [suggestionValue, setSuggestionValue] = useState();

  const [showResponse, setShowResponse] = useState(showres);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(response)
      .then(() => {
        SuccessToast("Copied To Clipboard!");
      })
      .catch((err) => {});
  };
  useEffect(() => {
    setQueries();
  }, [id, isHistory, conversationById]);

  const setQueries = () => {
    const queries = conversationById?.queries || [];
    const firstQuery = queries[0];
    setConvoId(queries?.map((e) => e?.id));


    if (queries?.length > 0) {
      const responseText =
        firstQuery?.modified_response || firstQuery?.response;

      setResponse(formatResponseText(responseText));
      setSuggestionValue(firstQuery?.options);
      setQuery(firstQuery?.query);
    } else {
      setResponse(formatResponseText(conversationById?.response));
      setSuggestionValue(conversationById?.options);
      setQuery(conversationById?.query);
    }
  };

  

  const modifyResponse = async (formikvalues) => {
    setIsModify(true);

    let modifyValue = {
      generated_prompt: query,
      input_for_modification: formikvalues,
      response_from_gpt: response ? response : data?.data?.response,
    };

    dispatch(modifyResponseApi(newId, modifyValue, setResponse, setIsLoading , formatResponseText));
  };

  return (
    <>
      <div className="responseuiinputcontainer">
        {query ? (
          <AccountCircleIcon
            style={{
              background: "intial",
              display: query ? `` : `none`,
            }}
            className="response-input-icon"
          />
        ) : null}

        {query ? (
          <p
            className="responseuiinput"
            style={{ display: query ? `` : `none` }}
          >
            {query ?? ""}
          </p>
        ) : null}
      </div>

      <>
        <div className="response-header">
          <p>Response</p>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
            className="copycontainer"
            onClick={handleCopy}
          >
            <ContentCopyOutlinedIcon /> <p>Copy Response</p>
          </div>
        </div>
        <div className="responsetextdiv">
          {isLoading || modifyLoading ? (
            <Loader />
          ) : (
            <p
              className="responestyle"
              style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{
                __html: response,
              }}
            />
          )}
        </div>
      </>

      <div style={{ marginTop: "4%" }}>
        <ModifySuggestion
          isClass={false}
          onModifyClick={modifyResponse}
          suggestionValue={suggestionValue}
          setSuggestionValue={setSuggestionValue}
          setShowResponse={setShowResponse}
          showResponse={showResponse}
          resdata={data}
          setIsModify={setIsModify}
          ismodify={ismodify}
          conversationById={conversationById}
        />
      </div>
    </>
  );
};

export default ResponseUi;
