import SuggestionDropdown from "../../../components/common/suggestionDropdown";
import "./editsuggestion.css";
import QueryButton from "../../../components/common/querybutton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addChoiceApi,
  deleteChoiceApi,
  editChoiceApi,
} from "../../../redux/action/UserAction";
import { FailureToast } from "../../../utils/commonunctions";

const EditSuggestion = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [dataId, setDataId] = useState();
  const [categoryValue, setCategoryValue] = useState("");
  const { id, isEdit, finaldata } = location?.state;
  const dispatch = useDispatch();
  const handleAdd = async () => {
    const payload = {
      choice_type: id,
      value: categoryValue,
    };

    if (payload.choice_type === "length" && parseInt(payload.value) < 10) {
      FailureToast("Length Require Minimum 10");
    } else {
      dispatch(addChoiceApi(navigate, payload));
    }
  };

  const handleEdit = async () => {
    const payload = {
      choice_type: id,
      value: categoryValue,
    };

    if (payload.choice_type === "length" && parseInt(payload.value) < 10) {
      FailureToast("Length Require Minimum 10");
    } else {
      dispatch(editChoiceApi(navigate, id, dataId, payload));
    }
  };

  const handleDelete = async () => {
    dispatch(deleteChoiceApi(navigate, id, dataId));
  };

  const addCategory = async () => {
    navigate(-1);
  };

  const choiceOption = (e) => {
    const selectedValue = e?.target?.value;

    setDataId(
      e?.target?.options[e?.target?.selectedIndex].getAttribute("data-id")
    );

    setCategoryValue(selectedValue);
  };

  return (
    <>
      <div className="maincontenthead">Edit Suggestion</div>
      <div className="editsuggestioncontainer">
        <div className="editsuggestionoption">
          <SuggestionDropdown
            isEdit={isEdit}
            customstyle={{ width: "30%" }}
            finaldata={finaldata}
            value={categoryValue}
            onChange={choiceOption} 
          />
        </div>
        <div className="editsuggestionfillsection">
          <input
            className="suggestioninput"
            placeholder="Edit Category"
            value={categoryValue}
            onChange={(e) => setCategoryValue(e?.target?.value)}
            type={id === "length" ? "number" : "text"}
          />
          <div className="edit-icons">
            <div
              onClick={dataId ? undefined : () => handleAdd()}
              style={{
                cursor: dataId ? "not-allowed" : "pointer",
              }}
            >
              <AddCircleOutlineIcon
                style={{
                  color: dataId ? "#a9a9a9" : "#35A600",
                  fontSize: "25px",
                }}
              />
            </div>
            <div
              onClick={dataId ? handleEdit : undefined}
              style={{ cursor: dataId ? "pointer" : "not-allowed" }}
              aria-disabled={!dataId}
            >
              <EditNoteIcon
                style={{
                  color: dataId ? "#F29D38" : "#a9a9a9",
                  fontSize: "25px",
                }}
              />
            </div>
            <div
              onClick={dataId ? handleDelete : undefined}
              style={{ cursor: dataId ? "pointer" : "not-allowed" }}
              aria-disabled={!dataId}
            >
              <DeleteIcon
                style={{
                  color: dataId ? "#F70000" : "a9a9a9",
                  fontSize: "25px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
      >
        <QueryButton btntext={"Cancel"} btnclick={addCategory} />
      </div>
    </>
  );
};

export default EditSuggestion;
