import language from "../assest/image/Language.png";
import account from "../assest/image/account.png";
import Lists from "../assest/image/Lists.png";
import output from "../assest/image/output.png";
import subject from "../assest/image/subject.png";
import "./helperdata.css";
import { Box } from "@mui/material";

export function getFormattedDate() {
  const date = new Date();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; 
  return `${month} ${day} ${hours}:${minutes} ${ampm}`;
}
  export const iconfun = (iconName) => {
    const iconMap = {
      language: language,
      account: account,
      Lists: Lists,
      output: output,
      subject: subject,
      default: <svg data-testid="DeleteIcon"></svg>,
    };
    return iconMap[iconName];
  };

  export function formatDateTime(dateString) {
    return new Date(dateString).toLocaleString("en-US", {
      month: "long",
      day: "2-digit",
      year: "numeric",
    });
  }

 export function CircularIndeterminate() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <span>Submitting Query </span>
      <span class="dot-one"> .</span>
      <span class="dot-two"> .</span>
      <span class="dot-three"> .</span>
    </Box>
  );
}

export  const formatResponseText = (text) => {
  if (!text) return "";

  return text
    .replace(/\*\*(.*?)\*\*/g, (match, p1) => {
      return `<b>${p1}</b>`; // Replace with <b> tags, preserving text inside **
    })
    .replace(/(##+)\s*(.*?)(\n|$)/g, (match, p1, p2) => {
      return `<b>${p2}</b>\n`; // Bold the text following ## or ### until the end of the line
    });
};
