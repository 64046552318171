import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          minHeight: "400px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="black" />
      </Box>
    </div>
  );
};

export default Loader;
